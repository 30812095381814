




















































































































































































































































































































































































































// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import baseUserContextPage from "../baseUserContextPage";
import axios from "axios";
import { sisasOrderModel, orderState } from "@/models/orderModel";
import { sisasDocumentiRighe, orderDetailModel } from "@/models/orderDetailModel";
import _ from "lodash";
import { sisasDocumentiEvasione } from "@/models/sisasDocumentiEvasione";
import { AddedSkuToCartResponse, AddSkuToCart, AddSkuToCartRequest, productModel, QuickAddSkuToCart } from "@/models/productModel";
import eventHub from "@/eventHub";

@Component({})
export default class orderPage extends baseUserContextPage {
	isLoading: boolean = true;
	isAddingToCart: boolean = false;
	isUpdateLoading: boolean = false;
	openAttachment: boolean = false;
	order: sisasOrderModel = new sisasOrderModel();
	get isERPAlign(): boolean {
		var instance = this;
		return instance.order != null && (instance.order.orderState == orderState.Completed || instance.order.orderState == orderState.Cancelled || instance.order.orderState == orderState.Processing);
	}
	get code(): string {
		return this.$route && this.$route.params && this.$route.params.code ? this.$route.params.code : "none";
	}
	get customerReferenceCode(): string {
		var instance = this;
		return instance.order != null && instance.order.customer_ReferenceCode != null && instance.order.customer_ReferenceCode != "" ? instance.order.customer_ReferenceCode : null;
	}
	get showCustomerLink(): boolean {
		return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
	}
	get showGoToMyAreaLink(): boolean {
		if (this.prevRoute == null) return this.getCurrentCustomer != null;

		if (this.prevRoute.name == "orders") return false;
		return true;
	}
	get orderNumber(): string {
		var instance = this;
		return instance.order != null && instance.order.orderSourceId != null ? instance.order.orderSourceId.split("_")[0] : instance.order.orderSourceId;
	}

	get sourceId(): string {
		var instance = this;
		return instance.order != null && instance.order.orderSourceId != null && instance.order.orderSourceId.split("_").length > 0
			? instance.order.orderSourceId.split("_")[1]
			: instance.order.orderSourceId;
	}
	get ordineAnnullabile(): boolean {
		var instance = this;
		if (instance.order == null) return false;
		if (instance.order.orderState != null && instance.order.orderState == orderState.Cancelled) return false;
		if (instance.order.sisasDocumentiTestata != null && instance.order.sisasDocumentiTestata.flagprovv == "S") return true;
		return false;
	}
	get priceOriginalTot(): number {
		var instance = this;
		if (instance.order == null) return null;
		if (instance.order.orderDetails == null || instance.order.orderDetails.length == 0) return 0;
		return _.sumBy(instance.order.orderDetails, function(od) {
			// not summ omaggio
			return (od as orderDetailModel).price == 0 ? 0 : (od as orderDetailModel).quantity * (od as orderDetailModel).originalPrice;
		});
	}
	get discountTot(): number {
		var instance = this;
		if (instance.order == null) return null;
		if (instance.order.orderDetails == null || instance.order.orderDetails.length == 0) return 0;
		return instance.priceOriginalTot - instance.order.grandTotal + instance.order.taxTotal;
	}
	get rowsDetails(): sisasDocumentiRighe[] {
		var instance = this;
		if (instance.order == null || instance.order.sisasDocumentiRighe == null) return [];
		var filterd = instance.order.sisasDocumentiRighe.filter(r => r.tiporiga != "D").sort((a, b) => (a.seqriga > b.seqriga ? 1 : 0));
		return filterd;
	}
	get enableOrderAgain(): boolean {
		return this.details.find(x => x.selected) != null;
	}
	selectAll(val: boolean) {
		if (this.order.orderDetails != null) this.order.orderDetails.forEach(x => (x.selected = val));
	}
	isOmaggio(row: sisasDocumentiRighe): boolean {
		return row.omaggio == "IMPONIBILE+IVA";
	}
	isProductOmaggio(product: orderDetailModel): boolean {
		var instance = this;
		var row = instance.getRowDetail(product);
		return row != null ? instance.isOmaggio(row) : false;
	}
	isChildProduct(product: orderDetailModel): boolean {
		var instance = this;
		var row = instance.getRowDetail(product);
		return row != null ? row.valriglor <= 0 : false;
	}
	getProductNetTotal(product: orderDetailModel): number {
		if (product == null || product.netPrice == null || product.quantity == null) return 0;
		else return product.netPrice * product.quantity;
	}
	getProductPriceTotal(product: orderDetailModel): number {
		if (product == null || product.price == null || product.quantity == null) return 0;
		else return product.price * product.quantity;
	}
	getAttachment(product: orderDetailModel): sisasDocumentiEvasione {
		var instance = this;
		if (instance.order == null || instance.order.sisasDocumentiEvasioni == null) return null;
		var row = instance.getRowDetailField(product, "idriga");

		var d = instance.order.sisasDocumentiEvasioni.find(x => x.rigA_ORDINE == row);
		return d;
	}
	getShowSconto(field: string) {
		var instance = this;
		if (instance.order == null || instance.order.sisasDocumentiRighe == null) return false;
		return (
			instance.order.sisasDocumentiRighe.find(x => {
				return instance.isOmaggio(x) == false && x[field];
			}) != null
		);
	}
	get showCode3() {
		var instance = this;
		if (instance.order == null || instance.order.orderDetails == null) return false;
		return instance.order.orderDetails.find(x => x.code3 != null && x.code3 != "") != null;
	}
	get showCode4() {
		var instance = this;
		if (instance.order == null || instance.order.orderDetails == null) return false;
		return instance.order.orderDetails.find(x => x.code4 != null && x.code4 != "") != null;
	}
	get showAddress(): boolean {
		var instance = this;
		return instance.order != null && instance.order.orderAddresses != null && instance.order.orderAddresses.length > 0;
	}
	get customerUrl(): string {
		return `/customer/${this.order.customer_Id}`;
	}
	get details(): Array<orderDetailModel> {
		var instance = this;
		if (instance.order == null || instance.order.orderDetails == null) return [];
		return instance.order.orderDetails.sort((a, b) => {
			return instance.getRowDetailField(a, "seqriga") > instance.getRowDetailField(b, "seqriga") ? 1 : -1;
		});
	}
	get attachments(): Array<sisasDocumentiEvasione> {
		var instance = this;
		if (instance.order == null || instance.order.sisasDocumentiEvasioni == null) return [];
		return instance.order.sisasDocumentiEvasioni.sort((a, b) => {
			return a.rigA_ORDINE > b.rigA_ORDINE ? 1 : -1;
		});
	}
	getPosizione(a: sisasDocumentiEvasione): string {
		var instance = this;
		if (instance.order == null || instance.order.sisasDocumentiRighe == null) return "";
		var d = instance.order.sisasDocumentiRighe.find(x => x.idriga == a.rigA_ORDINE);
		if (d == null || d.tiporiga == "D") return "";
		return d.seqriga;
	}
	getRowDetail(product: orderDetailModel): sisasDocumentiRighe {
		var instance = this;
		if (product == null || instance.order == null || instance.order.sisasDocumentiRighe == null) return null;
		var row = instance.order.sisasDocumentiRighe.find(x => x.idriga == product.code);
		return row;
	}
	getRowDetailField(product: orderDetailModel, field: string) {
		var instance = this;
		var row = instance.getRowDetail(product);
		return row != null ? (row as any)[field] : "";
	}
	getLabelProporzione(product: orderDetailModel): string {
		let qUM = Number.parseInt(product.code5 as string);
		if (qUM == null || qUM == 0) return "";

		let quantity = (product.quantity || 0) / qUM;
		if (quantity == 1) return "Unitario";
		else if (quantity > 1) return "Multipli di " + quantity + " pz.";
		else return quantity + " del pezzo";
	}
	getDetailUrl(product: productModel): string {
		return "/product/" + product.id;
	}
	fetch(): void {
		var instance = this;
		instance.isLoading = true;
		axios
			.get<sisasOrderModel>(`/order/${instance.code}`)
			.then(res => {
				instance.order = res.data ? res.data : new sisasOrderModel();
				if (instance.order.orderDetails != null) instance.order.orderDetails.forEach(x => instance.$set(x, "selected", false));

				instance.isLoading = false;
				instance.fetchUpdate();
			})
			.catch(error => {
				console.error(error);
				instance.order = new sisasOrderModel();
				instance.isLoading = false;
			});
	}
	fetchUpdate(): void {
		var instance = this;
		// ora viene aggiornato direttamente dal server
		// if (
		//   instance.order.orderState != orderState.New &&
		//   instance.order.orderState != orderState.Cancelled &&
		//   (instance.order.paymentState == "Pending" ||
		//     instance.order.paymentState == "Received")
		// ) {
		//   instance.isUpdateLoading = true;
		//   axios
		//     .get<sisasOrderModel>(`/order/update/${instance.order.id}`)
		//     .then(res => {
		//       instance.order = res.data ? res.data : new sisasOrderModel();
		//       instance.isUpdateLoading = false;
		//     })
		//     .catch(error => {
		//       console.error(error);
		//       instance.isUpdateLoading = false;
		//     });
		// }
	}
	onAnnulla(): void {
		var instance = this;
		instance.isLoading = true;
		axios
			.get<sisasOrderModel>(`/order/delelte/${instance.order.id}`)
			.then(res => {
				instance.order = res.data ? res.data : new sisasOrderModel();
				instance.isLoading = false;
				if (instance.order.orderState == orderState.Cancelled) {
					instance.$notify({
						title: "",
						text: "L'ordine è stato cancellato",
						type: "success",
						group: "msg"
					});
				} else {
					instance.$notify({
						group: "msg",
						type: "error",
						title: "Non è stato possibile cancellare l'ordine",
						text: "Riprovare più tardi o contattare il servizio clienti"
					});
				}
			})
			.catch(error => {
				console.error(error);
				instance.isLoading = false;
			});
	}
	onInvia(): void {
		var instance = this;
		instance.isLoading = true;
		axios
			.get<sisasOrderModel>(`/order/send/${instance.order.id}`)
			.then(res => {
				instance.order = res.data ? res.data : new sisasOrderModel();
				instance.isLoading = false;
				if (instance.order.orderState == orderState.New) {
					instance.$notify({
						group: "msg",
						type: "error",
						title: "Invio dell'ordine non riuscito",
						text: "Riprovare più tardi o contattare il servizio clienti"
					});
				} else {
					instance.$notify({
						title: "",
						text: "L'ordine è stato inviato",
						type: "success",
						group: "msg"
					});
				}
			})
			.catch(error => {
				console.error(error);
				instance.isLoading = false;
			});
	}
	onAggiungiAlCarrello(): void {
		var instance = this;
		instance.isAddingToCart = true;

		var selecteds = instance.details.filter(x => x.selected);
		if (selecteds != null && selecteds.length > 0) {
			var request = new AddSkuToCartRequest();

			selecteds.forEach(x => {
				if (request.products.find(r => r.sku == x.sku) == null) {
					var p = new AddSkuToCart();
					p.customerId = instance.order.customer_Id;
					p.sku = x.sku;
					p.quantity = 1;
					request.products.push(p);
				}
			});
			axios
				.post<AddedSkuToCartResponse>(`cart/addproducts`, request)
				.then(res => {
					if (instance.getCurrentCustomerId == instance.order.customer_Id) instance.setCurrentCart(res.data.cart);
					res.data.products.forEach(x => instance.$set(x, "source", "orderPage"));
					eventHub.$emit(eventHub.events.CART_UPDATED, res.data.products);

					instance.isAddingToCart = false;
				})
				.catch(error => {
					console.error(error);
					instance.isAddingToCart = false;
				})
				.finally(() => (instance.isAddingToCart = false));
		} else instance.isAddingToCart = false;
	}
	beforeMount() {}
	created() {
		var instance = this;

		instance.fetch();
	}
	get progressBarValue() {
		if (!this.order) return;

		switch (this.order.orderState) {
			case orderState.Processing:
				return 33;
			case orderState.Shipping:
				return 66;
			case orderState.Completed:
				return 100;
			default:
				return 0;
		}
	}
}
